<script lang="ts">
	import { nbspify } from "../../../core/utils/nbspify.js";

	export let title: string;
	export let className = "";
</script>

<p class="{className} text-lg font-semibold leading-[1.13] sm:text-xl md:text-2xl xl:text-4xl">
	{#each title.split("\n") as line}
		{nbspify(line)}
		<br />
	{/each}
</p>
