<script lang="ts">
	import { getTranslate } from "../../utils/getTranslate";
	import { flyInOnScroll } from "../../utils/flyInOnScroll.js";
	import type { LocalizedString } from "../../../../core/schema/Locale";

	export let translations: Record<string, LocalizedString>;

	const translate = getTranslate(translations);
	/* eslint-disable svelte/no-at-html-tags */
</script>

<div
	class="container flex min-h-screen flex-col justify-between gap-[3.0625rem] pb-20 pt-[8.875rem] lg:flex-row lg:items-center lg:gap-4 lg:pb-[8.75rem] lg:pt-[12.875rem]"
	id="showMore"
>
	<div class="flex flex-col items-start gap-[3.0625rem] lg:w-[51rem] lg:gap-[6.0625rem]">
		{#if translations.headline}
			<h2 use:flyInOnScroll class="m-0 p-0 text-xl font-semibold md:text-4xl md:leading-[1.135] lg:w-[85%]">
				{translate("headline")}
			</h2>
		{/if}

		{#if translations.subHeadline}
			<p use:flyInOnScroll class="lg:text-2lg text-lg leading-[1.1875] lg:w-9/12">
				{translate("subHeadline")}
			</p>
		{/if}
	</div>
	{#if translations.textSubheadline ?? translations.text}
		<p use:flyInOnScroll class="lg:mb-48 lg:w-[28rem]">
			{#if translations.textSubheadline}
				<span class="mb-6 font-bold">{translate("textSubheadline")}</span>
			{/if}
			{#if translations.text}
				<br /><br />{@html translate("text")}
			{/if}
		</p>
	{/if}
</div>
